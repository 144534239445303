import IconHome from '~/shared/assets/icons/dark/icon-home.svg';
import IconHomeColor from '~/shared/assets/icons/color/icon-home.svg';
import IconSearch from '~/shared/assets/icons/dark/icon-search.svg';
import IconSearchColor from '~/shared/assets/icons/color/icon-search.svg';
import IconSwipe from '~/shared/assets/icons/dark/icon-swipe.svg';
import IconSwipeColor from '~/shared/assets/icons/color/icon-swipe.svg';
import IconMedia from '~/shared/assets/icons/dark/icon-media.svg';
import IconMediaColor from '~/shared/assets/icons/color/icon-media.svg';
import IconSupport from '~/shared/assets/icons/dark/icon-support.svg';
import IconSupportColor from '~/shared/assets/icons/color/icon-support.svg';

export const ACCOUNT_NAVIGATION = [
  {
    label: 'Home',
    href: '/account',
    icon: { base: IconHome, active: IconHomeColor },

  },
  {
    label: 'Search',
    href: '/catalog',
    icon: { base: IconSearch, active: IconSearchColor },
  },
  {
    label: 'Swipe',
    href: '/swipes',
    icon: { base: IconSwipe, active: IconSwipeColor },
  },
  {
    label: 'Media',
    href: '/media',
    icon: { base: IconMedia, active: IconMediaColor },
  },
  {
    label: 'Support',
    href: '/support',
    icon: { base: IconSupport, active: IconSupportColor },
  },
];