<template>
  <div :class="styles.root">
    <HeaderAccount/>
    <main :class="styles.main">
      <slot/>
    </main>
    <Footer :class="styles.footer"/>
    <AuthModal/>
    <CookieModal/>
    <PageLoader v-if="isActive"/>
  </div>
</template>

<script lang="ts" setup>
import styles from './MainAccountLayout.module.scss';
import { Footer } from '~/widgets/Footer';
import { AuthModal } from '~/features/auth';
import { CookieModal } from '~/features/CookieModal';
import { PageLoader } from '~/shared/ui/loader';
import { usePreloader } from '~/features/loader';
import { storeToRefs } from 'pinia';
import { HeaderAccount } from '~/widgets/HeaderAccount';

const { isActive } = storeToRefs(usePreloader());
</script>
