<template>
  <button :class="styles.root" @click="toggleMenu()">
    <IconClose v-if="isMenuOpen" width="24" height="24"/>
    <IconNavigation v-else width="24" height="24"/>
  </button>
</template>

<script lang="ts" setup>
import styles from './HeaderAccountMobileButton.module.scss';
import { useMobileMenu } from '../../store/useMobileMenu';
import { storeToRefs } from 'pinia';
import IconNavigation from '~/shared/assets/icons/icon-menu-btn.svg';
import IconClose from '~/shared/assets/icons/icon-close.svg';

const { isMenuOpen } = storeToRefs(useMobileMenu());
const { toggleMenu } = useMobileMenu();
</script>