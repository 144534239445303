import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

interface useMobileMenuProps {
    isMenuOpen: Ref<boolean>;
    openMenu: () => void;
    closeMenu: () => void;
    toggleMenu: () => void;
}

export const useMobileMenu = defineStore('mobileMenu', (): useMobileMenuProps => {
  const isMenuOpen = ref(false);

  const openMenu = (): void => {
    isMenuOpen.value = true;
    document.body.style.overflow = 'hidden';
  }

  const closeMenu = (): void => {
    isMenuOpen.value = false;
    document.body.style.overflow = '';
  }

  const toggleMenu = (): void => {
    isMenuOpen.value = !isMenuOpen.value;
    document.body.style.overflow = isMenuOpen.value ? 'hidden' : '';
  }

  return {
    isMenuOpen,
    openMenu,
    closeMenu,
    toggleMenu,
  };
});